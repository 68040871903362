.noItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    font-family: 'Courier New', Courier, monospace;
    color: black;
    font-weight: bold; /* Makes the text bolder */
}
