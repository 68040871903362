/* Remove the .panel class from the CSS */
.navbar {
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  color: white;
  width: 300px;
  box-sizing: border-box;
  padding: 30px 10px 30px 10px;
  min-height: 100vh;
  align-items: center;
  
  /* Move .navbar-footer outside .navbar-content */
  .navbar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex: 1;
  }
  
  .navbar-button {
    width: 100%;
  }
  
  .navbar-footer {
    margin-top: auto;
  }

  .admin-button {
    background-color: red;
  }
}

