.noMatch {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 120px;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    letter-spacing: 5px;
    text-shadow: 2px 2px 4px #000000;
}
