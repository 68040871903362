.panel {
    display: flex;
    min-height: 100vh;

    .main-content {
        display: flex;
        flex: 1;
        margin: 40px;
        border-radius: 15px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5); /* This will add the shadow */    }
}
