/* LogViewer.scss */

.log-viewer {
    flex-grow: 1;
    display: flex;
    height: 84vh;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  