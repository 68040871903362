.loginPage {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #282c34; /* Same color as the loginPage background */
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5); /* This will add the shadow */

        .inputComponent {
            margin-bottom: 15px;
            width: 100%;
        }
    }
}
